<template>
  <v-card class="d-flex justify-center align-center flex-column " :flat="!outline" :class="{'pa-3': outline }">
    <div class="text-caption">{{ label }}</div>
    <slot name="value" v-bind="{ value }">
      <div class="text-h5 font-weight-black">{{ value }} </div>
    </slot>
  </v-card>
</template>

<script>
export default {
  name: "SingleValueViewer",

  data: function() {
    return {};
  },
  props: {
    value: undefined,
    label: undefined,
    outline: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
</style>