<template>
  <div class="d-flex justify-center align-center flex-column">
    <div class="d-flex justify-space-around align-center mt-2">
      <!--<SingleValueViewer label="Previsionale" :value="!previsional ? '0' : previsional + ' €'" outline class="mx-2">
      </SingleValueViewer>

      <SingleValueViewer label="Totale Clienti" :value="totalCustomer" class="mx-2" outline></SingleValueViewer>

      <SingleValueViewer label="Totale Servizi" :value="totalService" class="mx-2" outline></SingleValueViewer>-->
      <v-card class="py-1 px-12 mt-1 mx-2 d-flex flex-column align-center">
        <div>
          {{this.$vuetify.breakpoint.lg ? 'Prev:' : 'Previsionale:'}} 
          <span class="font-weight-black">{{ !!previsional ? previsional + ' €' : '0 €' }}</span>
        </div>
        <div>
          {{this.$vuetify.breakpoint.lg ? 'Tot. Cli:' : 'Totale Clienti:'}}
          <span class="font-weight-black">{{totalCustomer}}</span>
        </div>
        <div>
          {{this.$vuetify.breakpoint.lg ? 'Tot. Serv:' : 'Totale Servizi:'}}
          <span class="font-weight-black">{{totalService}}</span>
        </div>
      </v-card>
    </div>
    <PieChart v-if="type == 'pie' && loadedData && !emptyData" class="mt-5" :chart-data="chartData" :styles="{
      height: height,
      width: width
    }" :options="chartOptions"></PieChart>
    <DoughnutChart v-else-if="type == 'doughnut' && loadedData && !emptyData" class="mt-5" :chart-data="chartData" :styles="{
      height: height,
      width: width
    }" :options="chartOptions"></DoughnutChart>
    <v-card
      :height="height"
      :width="width"
      class="pa-1 mt-7" 
      outlined
      v-if="!!emptyData"
    >
      <div class="d-flex align-center" style="width:100%; height: 100%;">
        <div class="text-center" style="width: 100%;">
          DATI INSUFFICIENTI
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import calendarService from "@/services/calendar/calendar.service.js";
import PieChart from "@/components/charts/PieChart";
import DoughnutChart from "@/components/charts/DoughnutChart";
import SingleValueViewer from "@/components/common/SingleValueViewer.vue";

export default {
  name: "CalendarAnalytics",
  components: {
    SingleValueViewer,
    PieChart,
    DoughnutChart
  },
  data: function () {
    return {
      previsional: undefined,
      totalCustomer: undefined,
      totalService: undefined,
      totalHoursWorked: undefined,
      loadedData: false,
      emptyData: false,
      chartOptions: {
        mainAspectRatio: true,
        responsive: true,
        legend: {
          display: false
        },
        showTooltips: false,
        tooltips: {
          callbacks: {
            label: function (context, data) {
              const totalMinutes = data.datasets[context.datasetIndex].data[context.index]
              const operatorName = data.labels[context.index]
              const hours = Math.floor(totalMinutes / 60)
              const minutes = totalMinutes % 60
              const timeLabel = hours == 0 ? minutes + ' minuti' : hours + 'h e ' + minutes + 'm'

              return ' ' + operatorName + ': ' + timeLabel + ' | ' + data.previsionals[context.index]
            }
          }
        },
      },
    }
  },
  props: {
    date: {
      type: Date,
      default: function () {
        return new Date();
      }
    },
    height: {
      type: String,
      default: "300px"
    },
    width: {
      type: String,
      default: "300px"
    },
    type: {
      type: String,
      default: "pie"
    },
    lightColor: {
      type: Boolean,
      default: false
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load() {
      calendarService.analyticsPrevisional(this.date).then(results => {
        this.previsional = results.previsional;
        this.totalCustomer = results.totalCustomer;
        this.totalService = results.totalService;
        this.totalHoursWorked = results.totalHoursWorked
        this.loadedData = true
      });
    }
  },
  computed: {
    chartData() {
      if (!this.totalHoursWorked) return undefined

      let chartData = {
        labels: [],
        previsionals: [],
        datasets: [
          {
            label: 'Ore Lavorate',
            borderColor: [],
            backgroundColor: [],
            pointBackgroundColor: 'white',
            borderWidth: 0,
            borderAlign: 'inner',
            pointBorderColor: 'black',
            data: [],
          }
        ]
      }
      for (let i = 0; i < this.totalHoursWorked.length; i++) {
        chartData.labels.push(this.totalHoursWorked[i].operator.firstname + ' ' + this.totalHoursWorked[i].operator.lastname)
        chartData.previsionals.push(!!this.totalHoursWorked[i].previsional ? this.totalHoursWorked[i].previsional + ' €' : '0 €')

        let hex = this.totalHoursWorked[i].operator.color
        let c = hex || '#000000'
        if (this.lightColor) {
          c = c.substring(1).split('');
          if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c = '0x' + c.join('');
          c = 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.6)'
        }

        chartData.datasets[0].backgroundColor.push(c)
        chartData.datasets[0].borderColor.push(c)


        if (!this.totalHoursWorked[i].hoursworked.hours) {
          this.totalHoursWorked[i].hoursworked.hours = 0;
        }
        if (!this.totalHoursWorked[i].hoursworked.minutes) {
          this.totalHoursWorked[i].hoursworked.minutes = 0
        }

        chartData.datasets[0].data.push(this.totalHoursWorked[i].hoursworked.hours * 60 + this.totalHoursWorked[i].hoursworked.minutes)
      }

      if(chartData.datasets[0].data.length == 0)
        this.emptyData = true
      else
        this.emptyData = false

      return chartData
    }
  },
  watch: {
    date() {
      this.load()
    }
  }
};
</script>

<style>

</style>